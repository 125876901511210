var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"title":_vm.$t('PriceQuotationAffiliateOperations.mediaFile'),"className":'col-6',"defaultImg":_vm.fullPathFileFromServer(
            _vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationImagePath,
            _vm.priceQuotationAffiliateOperation.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationImageIsDefault &&
          _vm.checkPrivilege(_vm.hasPriceQuotationAffiliateOperationDeleteImage())},on:{"changeValue":function($event){_vm.priceQuotationAffiliateOperation.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}}),_c('CustomFileInput',{attrs:{"title":_vm.$t('PriceQuotationAffiliateOperations.designFile'),"className":'col-6',"defaultImg":_vm.fullPathFileFromServer(
            _vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationFilePath,
            _vm.priceQuotationAffiliateOperation.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationFileIsDefault &&
          _vm.checkPrivilege(_vm.hasPriceQuotationAffiliateOperationDeleteImage())},on:{"changeValue":function($event){_vm.priceQuotationAffiliateOperation.designFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.priceQuotationAffiliateOperation.fullCode,"title":_vm.$t('PriceQuotationAffiliateOperations.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.priceQuotationAffiliateOperation.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-priceQuotationAffiliateOperationTitleAr"),"errors":_vm.errors_priceQuotationAffiliateOperationTitleAr,"value":_vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationTitleAr,"title":_vm.$t('PriceQuotationAffiliateOperations.titleAr'),"imgName":'PriceQuotationAffiliateOperations.svg'},on:{"changeValue":function($event){_vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationTitleAr =
              $event;
            _vm.$v.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationTitleAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-priceQuotationAffiliateOperationTitleEn"),"errors":_vm.errors_priceQuotationAffiliateOperationTitleEn,"value":_vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationTitleEn,"title":_vm.$t('PriceQuotationAffiliateOperations.titleEn'),"imgName":'PriceQuotationAffiliateOperations.svg'},on:{"changeValue":function($event){_vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationTitleEn =
              $event;
            _vm.$v.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationTitleEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-priceQuotationAffiliateOperationNotes"),"value":_vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.priceQuotationAffiliateOperation.priceQuotationAffiliateOperationNotes =
              $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }